<template>
  <b-container class="pt-2">
    <div class="flex-grow-1 d-flex flex-column py-3">
      <b-row class="d-flex justify-content-between">
        <b-col cols="3">
          <b-img
            :src="$root.seminary.logo"
            :alt="$root.seminary.title"
            class="logo"
          />
        </b-col>
        <b-col class="text-right" cols="9">
          <h2 class="mt-3">
            {{ roundFullName }}
          </h2>
          <h5>
            Termín odoslania:&nbsp;&nbsp;<strong>{{ endDate }}</strong>
          </h5>
          <h5 class="mt-1">
            Svoje riešenia nahraj na stránke
            <b-link :href="submitURL">
              {{ submitURL }}
            </b-link>
          </h5>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { utils } from "frontend-common";
import constants from "@/constants";

export default {
  props: {
    round: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      constants,
    };
  },
  computed: {
    submitURL() {
      return `${this.$root.seminary.title.toLowerCase()}.sk`;
    },
    endDate() {
      return this.round ? utils.dateToString(this.round.end_time) : "";
    },
    roundFullName() {
      if (!this.round) return "";
      return utils.fullRoundName(this.round.contest.name, this.round.name);
    },
  },
  mounted() {
    // Force every <details> to be open
    document.querySelectorAll("details").forEach((el) => {
      el.open = true;
    });
  },
};
</script>

<style scoped>
.info-box {
  max-width: 15em;
  border-left: 0.1em solid var(--dark);
}

.info-box >>> * {
  line-height: 1.4em !important;
}

.text-muted {
  color: var(--pmat-gray) !important;
}

h5 {
  margin: 0;
  font-weight: 400;
}

.logo {
  max-width: 18em;
}
</style>
