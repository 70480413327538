<template>
  <div>
    <PrintHeader :round="selectedRound" />
    <div
      ref="container"
      :class="{ rendered }"
      :v-if="!!selectedRound && bookletLoaded"
    >
      <b-container>
        <p v-if="highlightProblems">
          <small>
            Úlohy označené <FavoriteStar /> sú určené pre tvoju kategóriu.
          </small>
        </p>
        <div v-for="block of booklet.blocks" :key="block.position" class="mt-3">
          <vue-markdown
            v-if="block.block_type === 'text'"
            :source="block.text"
            @rendered="openDetails"
          />
          <ProblemDetailPrint
            v-else-if="block.block_type === 'problem'"
            :id="'uloha-' + block.problem.number"
            :class="{ 'print-no-break': block.problem.statement.length < 1500 }"
            :problem="
              problems.find((problem) => problem.id === block.problem.id)
            "
            :highlight-problems="highlightProblems"
            :round="selectedRound"
          />
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { apiBooklets, apiRounds, utils } from "frontend-common";
import FavoriteStar from "@/components/utils/FavoriteStar.vue";
import PrintHeader from "@/components/PrintHeader.vue";
import ProblemDetailPrint from "@/components/ProblemDetailPrint.vue";
import VueMarkdown from "vue-markdown";

export default {
  name: "PrintView",
  components: {
    PrintHeader,
    FavoriteStar,
    VueMarkdown,
    ProblemDetailPrint,
  },
  mixins: [apiBooklets, apiRounds],
  props: {},
  data() {
    return {
      rounds: [],
      roundsLoaded: false,
      selectedRound: null,
      booklet: {},
      bookletLoaded: false,
      rendered: false,
    };
  },
  computed: {
    selectedContestRoundName() {
      if (!this.selectedRound || !this.selectedRound.contest) return "";
      return utils.fullRoundName(
        this.selectedRound.contest.name,
        this.selectedRound.name,
      );
    },
    problems() {
      if (!this.booklet.blocks) {
        return [];
      }
      const competition = this.$root.state.competition;
      const currentContest = competition.current_round
        ? competition.current_round.contest.id
        : null;
      return this.booklet.blocks
        .filter((block) => block.block_type === "problem")
        .map((block) => block.problem)
        .map((problem) => ({
          ...problem,
          recommendedForMe:
            this.selectedRound.contest.id === currentContest &&
            this.$root.isProblemRecommendedForMe(problem),
          allowedForMe:
            this.selectedRound.contest.id === currentContest &&
            this.$root.isProblemAllowedForMe(problem),
        }));
    },
    highlightProblems() {
      return this.problems.some((problem) => problem.recommendedForMe);
    },
  },
  watch: {
    "$root.stateLoaded": "loadContests",
  },
  mounted() {
    this.loadContests();
  },
  methods: {
    loadContests() {
      this.roundsLoaded = false;

      this.apiRounds()
        .then((response) => {
          this.rounds = response;
          this.loadRound();
        })
        .finally(() => (this.roundsLoaded = true));
    },
    loadRound() {
      if (this.rounds.length === 0) return;

      const roundId = parseInt(this.$route.params.roundId);
      this.selectedRound = this.rounds.find((round) => round.id === roundId);
      this.bookletLoaded = false;
      // TODO add support for multi-booklet rounds
      this.apiBooklet(this.selectedRound.booklet_set[0])
        .then((response) => (this.booklet = response))
        .finally(() => {
          this.bookletLoaded = true;
          this.waitForImages();
        });
    },
    async waitForImages() {
      // Wait for all images to load
      // if there are any
      const images = [...this.$refs.container.querySelectorAll("img")];
      if (images.length === 0) {
        setTimeout(() => (this.rendered = true), 500);
        return;
      }

      const events = images.map(
        (img) =>
          new Promise((resolve) => {
            img.addEventListener("load", resolve);
          }),
      );

      await Promise.all(events);

      this.rendered = true;
    },
    openDetails() {
      this.$nextTick(() => {
        this.$refs.container
          .querySelectorAll("details")
          .forEach((el) => (el.open = true));
      });
    },
  },
};
</script>

<style>
html,
body {
  outline: none;
  border: none;
  -webkit-print-color-adjust: exact;
}

@page {
  margin: 4em 0;
}

.print-no-break {
  break-inside: avoid;
}
</style>
