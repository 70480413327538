<!-- eslint-disable max-lines -->
<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col sm="auto">
          <b-card-title
            :class="{
              'text-muted': highlightProblems && !problem.recommendedForMe,
            }"
          >
            {{ problem.number }}. {{ problem.name }}
            <FavoriteStar
              v-if="problem.recommendedForMe"
              v-b-popover.hover="'Úloha je určená pre tvoju kategóriu.'"
            />
            <font-awesome-icon
              v-if="isInteractive"
              v-b-popover.hover.top="
                'Uloha je interaktívna! Budeš ju riešiť online!'
              "
              class="ml-2"
              :icon="['fas', 'gamepad']"
            />
          </b-card-title>
        </b-col>
        <b-col class="text-right mb-3">
          <CategoryBadge
            v-for="category of visibleCategories"
            :key="category.name"
            :recommended="category.recommended"
          >
            {{ category.name }}
          </CategoryBadge>
        </b-col>
      </b-row>
      <b-card-text>
        <advanced-markdown :source="problem.statement" :open-details="true" />

        <b-alert variant="primary" :show="!!problem.interactive">
          Táto úloha je interaktívna. Riešiť sa dá len priamo na stránke
          <b-link :href="submitURL">
            {{ baseURL }}
          </b-link>
        </b-alert>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  AdvancedMarkdown,
  analytics,
  apiSubmits,
  CategoryBadge,
} from "frontend-common";
import FavoriteStar from "@/components/utils/FavoriteStar.vue";

export default {
  name: "ProblemDetailPrint",
  components: {
    CategoryBadge,
    FavoriteStar,
    AdvancedMarkdown,
  },
  mixins: [apiSubmits, analytics],
  props: {
    problem: {
      type: Object,
      required: true,
    },
    highlightProblems: {
      type: Boolean,
      required: true,
    },
    round: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    baseURL() {
      return `${this.$root.seminary.title.toLowerCase()}.sk`;
    },
    submitURL() {
      return `https://${this.baseURL}/interactive/${this.problem.id}`;
    },
    dropZoneEnabled() {
      return (
        this.allowUpload &&
        this.$root.isAuthenticated &&
        this.$root.stateLoaded &&
        this.$root.state.user.organizer === null
      );
    },
    isInteractive() {
      return !!this.problem.interactive;
    },
    visibleCategories() {
      return this.problem.categories.filter((c) => !c.hidden);
    },
  },
  watch: {
    "$root.isAuthenticated": "updateSubmits",
    "$root.stateLoaded": "updateSubmits",
  },
  mounted() {},
  methods: {
    onSubmitUpload() {
      this.updateSubmits();
    },
  },
};
</script>

<style scoped lang="scss">
.score {
  font-size: 25px; /* TODO remove this hardcoded value */
}

.icon-indicator::before {
  border-color: var(--success);
}
</style>
