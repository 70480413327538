import { render, staticRenderFns } from "./PrintHeader.vue?vue&type=template&id=53c6d94c&scoped=true&"
import script from "./PrintHeader.vue?vue&type=script&lang=js&"
export * from "./PrintHeader.vue?vue&type=script&lang=js&"
import style0 from "./PrintHeader.vue?vue&type=style&index=0&id=53c6d94c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c6d94c",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImg, BCol, BLink, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BImg, BCol, BLink, BRow, BContainer})
    

export default component.exports