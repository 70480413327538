import { render, staticRenderFns } from "./ProblemDetailPrint.vue?vue&type=template&id=116ae068&scoped=true&"
import script from "./ProblemDetailPrint.vue?vue&type=script&lang=js&"
export * from "./ProblemDetailPrint.vue?vue&type=script&lang=js&"
import style0 from "./ProblemDetailPrint.vue?vue&type=style&index=0&id=116ae068&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "116ae068",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCardTitle, BCol, BRow, BLink, BAlert, BCardText, BCardBody, BCard} from 'bootstrap-vue'
    installComponents(component, {BCardTitle, BCol, BRow, BLink, BAlert, BCardText, BCardBody, BCard})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBPopover} from 'bootstrap-vue'
    installDirectives(component, {'b-popover': VBPopover})
    

export default component.exports